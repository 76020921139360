import authModule from '@/store/auth'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ObservationCrm from '@/Models/ObservationCrm'
import Crm from '@/Models/Crm'
import http from '@/services/http'
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class FormObservationCrm extends Vue {
  @Prop({ required: false, default: null })
  objMember!: Crm
  @Prop({ required: false, default: null })
  objRow!: ObservationCrm
  @Prop({ required: false, default: null })
  isAction!: string

  objObservationCrm: ObservationCrm = new ObservationCrm()
  loading = false

  get is_login(): boolean {
    return authModule.isAuthenticated
  }
  mounted() {
    this.objObservationCrm.member_number_id = this.objMember.member_number
    if (this.objRow && this.isAction == 'edit') {
      this.objObservationCrm.date_observation = this.objRow.date_observation
      this.objObservationCrm.observation = this.objRow.observation
    }
  }
  async confirmation() {
    //const observation = this.observation(this.objObservationCrm)
    switch (this.isAction) {
      case 'add':
        await this.createObservationCrm(this.objObservationCrm)
        break
      case 'edit':
        console.log(this.objObservationCrm)
        await this.updateObservationCrm(this.objObservationCrm)
        break
      default:
        console.log('ups')
        break
    }
  }
  async createObservationCrm(objObservationCrm: any) {
    try {
      await http.post(`api/crm/crm_observation/`, objObservationCrm)
      this.$buefy.toast.open({
        message: 'Agregado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error) {
      console.error(error)
      this.loading = false
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
  }
  async updateObservationCrm(objObservationCrm: any) {
    try {
      await http.put(
        `api/crm/crm_observation/${this.objRow.id}/`,
        objObservationCrm,
      )
      this.$buefy.toast.open({
        message: 'Actualizado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error: any) {
      this.loading = false
      console.log(error)
      switch (error.response.data.member_number[0]) {
        case 'Ya existe Crm Cliente Archivo con este member number.':
          this.$buefy.toast.open({
            message: `Ya exite un cliente con el mismo numero de miembro`,
            type: 'is-danger',
          })
          break
        default:
          this.$buefy.toast.open({
            message: `Ups!, algo salio mal`,
            type: 'is-danger',
          })
          break
      }
      /* this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: "is-danger",
      }) */
    }
  }
  /*  observation(objObservationCrm:ObservationCrm){
    const observation ={
     /*  ...objObservationCrm, 
      id: objObservationCrm.id,
      member_number: objObservationCrm.member_number,
      date_observation: objObservationCrm.date_observation.toISOString().split('T')[0],
      observation : objObservationCrm.observation
    }
    return observation
  }*/
  close() {
    this.objObservationCrm = new ObservationCrm()
    this.$emit('cleanForm')
  }
}
